import * as React from 'react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

// COMPONENTS
import { ShadowText } from 'components/styled/typography'
import { Button } from 'components/styled/button'

// ASSETS
import ArrowIcon from 'svgs/arrow_top_right.svg'
import { SCREEN } from 'styles/screens'
import { getTranslation } from '../../data/dictionary'
import { Picture } from 'components/styled/picture'
import fetch from 'node-fetch'
import { config } from '../../config'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 300px;
  padding: 40px 100px;
  .d-md-none {
    display: none
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    margin-top: 200px !important;
    .d-md-none {
      display: block
    }
    padding: 0;
    margin-top: 48px;
    .px-16px {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  picture {
    img,
    source {
      filter: grayscale(0.75);
    }

    &:hover {
      img,
      source {
        filter: grayscale(0);
      }
    }
  }

  img {
    filter: grayscale(0.75);

    &:hover {
      filter: grayscale(0);
    }
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 1520px;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
  }
`

const StyledHeading = styled.h2`
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    font-size: 1.8rem;
    padding: 0 12px;
  }
`

const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;

  picture {
    img,
    source {
      filter: grayscale(0.75);
    }

    &:hover {
      img,
      source {
        filter: grayscale(0);
      }
    }
  }

  img {
    filter: grayscale(0.75);

    &:hover {
      filter: grayscale(0);
    }
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    display: none;
  }

`

const GridOne = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;

  > div:first-child {
    flex: 1 1 25%;
  }

  > div:nth-child(2) {
    flex: 1 1 25%;
  }
`

const GridTwo = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;

  > div {
    flex: 1 1 33%;
    min-height: 200px;
  }
`

const GridThree = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;

  > div:not(:first-child) {
    flex: 1 1 20%;
  }
`

const GridOneDescription = styled.div`
  flex: 1 1 40%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;

  > div {
    flex: 1 0 68%;
    padding: 0 36px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: var(--primary);

    > h2,
    h5 {
      color: var(--secondary);
      margin: 0;
    }

    > h4 {
      color: var(--white);
      font-weight: 400;
      margin: 0;
      font-size: clamp(36px, 2vw, 40px);
    }

    > h2 {
      font-size: clamp(74px, 6.4vw, 110px);
    }

    > h5 {
      font-weight: 400;
      font-size: clamp(28px, 1.6vw, 30px);
    }
  }

  > button {
    flex: 0 0 16%;
    border-radius: 0;

    > svg {
      margin-left: 12px;
    }

    &:hover {
      background-color: var(--secondary);
      box-shadow: unset;
    }
  }

  @media only screen and (max-width: 1156px) {
    order: -1;
    flex: 1 1 100%;
    height: unset;
    min-height: 300px;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    > div {
      padding-left: 20px;
      padding-right: 15px;
      > h4, h5 {
        font-size: 16px;
        line-height: 16px;
      }
    
      > h2 {
        font-size: 58px;
        line-height: 30px;
      }
    }
  }
`

const GridThreeDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  flex: 1 1 40%;
  background-color: var(--primary);

  > div {
    flex: 1 0 75%;
    padding: 0 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    * {
      color: var(--secondary);
      margin: 0;
    }

    > h1 {
      //font-size: 200px;
      font-size: clamp(64px, 12vw, 200px);
      line-height: 1;
    }

    > h3,
    h4 {
      font-weight: 500;
    }
  }

  > button {
    flex: 0 0 16%;
    border-radius: 0;

    > svg {
      margin-left: 12px;
    }

    &:hover {
      background-color: var(--secondary);
      box-shadow: unset;
    }
  }

  @media only screen and (max-width: 1156px) {
    order: 3;
    flex: 1 0 100%;
    height: unset;
    min-height: 300px;
    > div {
      align-self: flex-start;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
    }
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    > div {
      padding-left: 20px;
      padding-right: 15px;
      flex-direction: column;
      align-items: flex-start;
      > h4 {
        font-size: 16px;
      }
      > h3 {
        font-size: 30px;
      }
      > h1 {
        font-size: 58px;
        line-height: 30px;
      }
    }
  }
`

const TrusteesContainer = styled.div`

`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const ColHalf = styled.div`
  flex: 0 0 50%;
  width: 50%;
  h2 {
    color: var(--white);
    margin: 0;
    font-size: 32px !important;
  }
  .mobile-grid {
    height: 100% !important;
  }
  &:last-child > div {
    &:last-child, &:nth-child(3) {
      img {
        max-height: 96% !important;
        height: 96% !important;
        max-width: 100%;
        width: 100%;
      }
    }
  }
  &:first-child > div, &:last-child > div {
    max-width: 50%;
    max-height: 50%;
    height: 50%;
    width: 50%;
    &:first-child, &:nth-child(2) {
      img {
        max-height: 105%;
        height: 105%;
        max-width: 100%;
        width: 100%;
      }
    }
   
    &:last-child, &:nth-child(3) {
      img {
        max-height: 95%;
        height: 95%;
        max-width: 100%;
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    h2, h2 span {
      font-size: clamp(32px, 4vw, 200px) !important;
    }
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    h5, h4 {
      font-size: 16px !important;
    }

  }

`

const MentorsSection = ({ handleModal }) => {
  const [images, setImages] = useState([])

  useEffect(() => {
    fetch(`${config.API_BASE_URL}/images/forHome`).then(response => {
      response.json().then(images => {
        setImages(images)
      })
    })
  }, [])

  if (images.length === 0) {
    return (
      <Container>
        <FixedContainer>
          <ShadowText>
            <StyledHeading>
              {getTranslation('mentors_heading', 'Trustees & Mentors')}
            </StyledHeading>
          </ShadowText>
        </FixedContainer>
      </Container>
    )
  }

  return (
    <Container>
      <FixedContainer>
        <div className='px-16px'>
          <ShadowText highlight>
            <h3>
            {
              getTranslation("mentors-eirs", "", true)
            }
            </h3>
          </ShadowText>
        </div>

        <GridContainer>
          <GridOne>
            <Picture
              src={images[0].imageUrl}
              alt="grid image"
              layout="constrained"
              width={452}
              height={514}
              placeholder="tracedSVG"
            />
            {images.length > 1 && (
              <Picture
                src={images[1].imageUrl}
                alt="grid image"
                layout="constrained"
                width={422}
                height={514}
                placeholder="tracedSVG"
              />
            )}
            <GridOneDescription>
              <div>
                <h4>
                  {getTranslation(
                    'mentors_grid_1_line_1',
                    'Join the community of TOP',
                  )}
                </h4>
                <h2>
                  {getTranslation('mentors_grid_1_line_2', 'CEOs & Founders')}
                </h2>
                <h5>
                  {getTranslation(
                    'mentors_grid_1_line_3',
                    'That will change the world',
                  )}
                </h5>
              </div>
              <Button onClick={handleModal}>
                {
                  getTranslation("apply-now", "", true)
                }<ArrowIcon />
              </Button>
            </GridOneDescription>
          </GridOne>
          <GridTwo>
            {images.length > 2 && (
              <Picture
                src={images[2].imageUrl}
                alt="grid image"
                layout="constrained"
                width={520}
                height={480}
                placeholder="tracedSVG"
              />
            )}
            {images.length > 3 && (
              <Picture
                src={images[3].imageUrl}
                alt="grid image"
                layout="constrained"
                width={520}
                height={480}
                placeholder="tracedSVG"
              />
            )}
            {images.length > 4 && (
              <Picture
                src={images[4].imageUrl}
                alt="grid image"
                layout="constrained"
                width={520}
                height={480}
                placeholder="tracedSVG"
              />
            )}
          </GridTwo>
          <GridThree>
            <GridThreeDescription>
              <div>
                <h4>{getTranslation('mentors_grid_3_line_1', 'More than')}</h4>
                <h1>{getTranslation('mentors_grid_3_line_2', '200+')}</h1>
                <h3>{getTranslation('mentors_grid_3_line_3', 'Mentors')}</h3>
              </div>
              <Button
                onClick={() => {
                  window.location.href = '/community/'
                }}
              >
                {getTranslation('explore_all_mentors', 'Explore All Mentors')}{' '}
                <ArrowIcon />
              </Button>
            </GridThreeDescription>
            {images.length > 5 && (
              <Picture
                src={images[5].imageUrl}
                alt="grid image"
                layout="constrained"
                width={370}
                height={480}
                placeholder="tracedSVG"
              />
            )}
            {images.length > 6 && (
              <Picture
                src={images[6].imageUrl}
                alt="grid image"
                layout="constrained"
                width={366}
                height={480}
                placeholder="tracedSVG"
              />
            )}
            {images.length > 7 && (
              <Picture
                src={images[7].imageUrl}
                alt="grid image"
                layout="constrained"
                width={286}
                height={480}
                placeholder="tracedSVG"
              />
            )}
          </GridThree>
        </GridContainer>

        <TrusteesContainer className='d-md-none'>
          <Row>
            <ColHalf>
              <Picture
                src={images[0].imageUrl}
                alt="grid image"
                layout="constrained"
                width={94}
                height={120}
                placeholder="tracedSVG"
              />
              {images.length > 1 && (
                <Picture
                  src={images[1].imageUrl}
                  alt="grid image"
                  layout="constrained"
                  width={94}
                  height={120}
                  placeholder="tracedSVG"
                />
              )}
              {images.length > 2 && (
                <Picture
                  src={images[2].imageUrl}
                  alt="grid image"
                  layout="constrained"
                  width={94}
                  height={120}
                  placeholder="tracedSVG"
                />
              )}
              {images.length > 3 && (
                <Picture
                  src={images[3].imageUrl}
                  alt="grid image"
                  layout="constrained"
                  width={94}
                  height={120}
                  placeholder="tracedSVG"
                />
              )}
            </ColHalf>
            <ColHalf>
              <GridOneDescription className='mobile-grid'>
                <div>
                  <h4>
                    {getTranslation(
                      'mentors_grid_1_line_1',
                      'Join the community of TOP',
                    )}
                  </h4>
                  <h2>
                    {getTranslation('mentors_grid_1_line_2', 'CEOs & Founders')}
                  </h2>
                  <h5>
                    {getTranslation(
                      'mentors_grid_1_line_3',
                      'That will change the world',
                    )}
                  </h5>
                </div>
                <Button onClick={handleModal}>
                {
                  getTranslation("apply-now", "", true)
                } <ArrowIcon />
                </Button>
              </GridOneDescription>
            </ColHalf>


            <ColHalf>
              <GridThreeDescription className='mobile-grid'>
                <div>
                  <h4>{getTranslation('mentors_grid_3_line_1', 'More than')}</h4>
                  <h1>{getTranslation('mentors_grid_3_line_2', '200+')}</h1>
                  <h3>{getTranslation('mentors_grid_3_line_3', 'Mentors')}</h3>
                </div>
                <Button
                  onClick={() => {
                    window.location.href = '/community/'
                  }}
                >
                  {getTranslation('explore_all_mentors', 'Explore All Mentors')}{' '}
                  <ArrowIcon />
                </Button>
              </GridThreeDescription>
            </ColHalf>
            <ColHalf>
              {images.length > 4 && (
                <Picture
                  src={images[4].imageUrl}
                  alt="grid image"
                  layout="constrained"
                  width={520}
                  height={480}
                  placeholder="tracedSVG"
                />
              )}
              {images.length > 5 && (
                <Picture
                  src={images[5].imageUrl}
                  alt="grid image"
                  layout="constrained"
                  width={370}
                  height={480}
                  placeholder="tracedSVG"
                />
              )}
              {images.length > 6 && (
                <Picture
                  src={images[6].imageUrl}
                  alt="grid image"
                  layout="constrained"
                  width={366}
                  height={480}
                  placeholder="tracedSVG"
                />
              )}
              {images.length > 7 && (
                <Picture
                  src={images[7].imageUrl}
                  alt="grid image"
                  layout="constrained"
                  width={286}
                  height={480}
                  placeholder="tracedSVG"
                />
              )}
            </ColHalf>
          </Row>
        </TrusteesContainer>
      </FixedContainer>
    </Container>
  )
}

export default MentorsSection
