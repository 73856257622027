import { TitleContainer, TitleText, Lin} from 'components/styled/typography'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import styled from 'styled-components'
import Play from 'svgs/play.svg' 
import { SCREEN } from 'styles/screens'
import { getTranslation } from 'data/dictionary'

const StudentsContainer = styled.div`
    display: flex;
    margin-left: 55px;
    flex-direction: row;
    column-gap: 50px;

    .info{
    width: 800px;

    @media only screen and (max-width: ${SCREEN.MOBILE}) {
        max-width: 350px;
        margin-left: 0px;
    }
    }

    .students{
        display: flex;
        flex-direction: row;
        column-gap: 70px;
        width: 100%;
        float: right;
        margin-left: 10px;

        @media only screen and (max-width: ${SCREEN.MOBILE}) {
        column-gap: 20px;
        }
    }

    @media only screen and (max-width: ${SCREEN.MOBILE}) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 0;
    }
`


const Container = styled.div`
display: flex;
flex-direction: column;

    
`;

const ChildContainer = styled.div`
    margin-left: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    @media only screen and (max-width: ${SCREEN.MOBILE}) {
        margin-left: 15px;
    }
`

const DescriptionContainer = styled.div`
    display: flex;
     margin-left: 30px;
    width: auto;
    flex-direction: column
`;

const H5 = styled.h5`
    font-weight: 300;
    max-width: 750px;
    line-height: 1.2;
`
const H6 = styled.h6`
    font-family: 'lexend';
    font-weight: 700;
    width: max-content;
    line-height: 1;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    bottom: 15px;
    left: 16px
`

const ImageContainer = styled.div`
    position: relative;
`

const LittleText = styled.p`
    font-family: 'lexend';
    font-weight: 400 !important;
    width: max-content;
    line-height: 1;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    bottom: 2px;
    left: 16px;
    font-size: 14px !important;
`

const StudentSection = () => {
    return(
    <div>
        <TitleContainer>
            <TitleText>{
                    getTranslation("our-students", "", true)
                }</TitleText>
        </TitleContainer>
        <Container>
        {/* <DescriptionContainer> */}
           
            {/* </DescriptionContainer> */}
            <StudentsContainer>
            <div className='info'><H5>
                {
                    getTranslation('buildup-students', "BuildUp School is perfect for juniors and mid-level professionals looking to elevate their careers in product management, design, marketing, or engineering. Our ideal students are ambitious individuals with some knowledge of English who are eager to master it and build digital products that showcase their skills to international employers. If you’re committed to learning by doing and ready to take your career to the next level, BuildUp School is the perfect place for you.", true)
                }
                </H5>
            </div>
            <div className='students'>
            <ImageContainer>
                <StaticImage src='../../images/pexels-pixabay-220453.png'/>
                <H6>
                    {
                        getTranslation('junior', "Junior", true)
                    }
                </H6>
                <LittleText>
                    {
                        getTranslation('standart', "Lorem ipsum dolor sit", true)
                    }
                </LittleText>
            </ImageContainer>
            <ImageContainer>
                <StaticImage src='../../images/pexels-pixabay-220453 2.png'/>
                <H6>
                     {
                        getTranslation('junior', "Junior", true)
                    }
                    </H6>
                <LittleText>
                {
                        getTranslation('standart', "Lorem ipsum dolor sit", true)
                }
                </LittleText>
            </ImageContainer>
            <ImageContainer>
                <StaticImage src='../../images/pexels-pixabay-220453 3.png'/>
                <H6>
                {
                        getTranslation('junior', "Junior", true)
                }
                </H6>
                <LittleText>
                {
                        getTranslation('standart', "Lorem ipsum dolor sit", true)
                }
                </LittleText>
            </ImageContainer>
            </div>
            </StudentsContainer>
        </Container>
    </div>
)}

export default StudentSection;