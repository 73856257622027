import * as React from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

// COMPONENTS
import { H1, ShadowText } from 'components/styled/typography'
import { fadeInText1, fadeInText2, fadeInText3, fadeInText4, fadeOut, load } from 'components/styled/animatedText'
import CloseIcon from '../../svgs/close-v2.svg'
import { Button, SecondaryButton } from 'components/styled/button'
import { FixedContainer } from '../styled/containers'

// ASSETS
import extension from '../../images/chrome-extension.png'
import oval from '../../images/Rectangle 104.png'
import otterGIF from '../../gifs/hero-animation.gif'
import PlayIcon from 'svgs/newDesigns/play-icon.svg'
import ArrowIcon from 'svgs/arrow_top_right.svg'
import { SCREEN } from 'styles/screens'
import { getTranslation } from '../../data/dictionary'
import AlbertSection from 'components/styled/AlbertSection'
import NewFocusSection from 'components/styled/newFocusSection'
import AlbertSectionResponsive from 'components/styled/AlbertSection-Responsive'
import { ShadowContainer } from 'components/styled/typography'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`


const Container = styled.div`
 
padding-inline: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px 0 0px;

  .mt-60px {
    margin-top: 60px !important;
  }

  .playIcon {
    position: absolute;
    top: 43%;
    left: 36%;
    transform: scale(1) translate(-50%, -50%);
    z-index: 100;
    animation: pulse-black 2s infinite, apearance 1s forwards;
    cursor: pointer;
    opacity: 0;
    // animation-delay: 5s, 4s;
    // transition: all 0.2s;
    transform-origin: 0 0;

    &:hover {
      transform: scale(2) translate(-50%, -50%);
    }

    @media only screen and (max-width: ${SCREEN.MOBILE}) {
      left: 35%;
    }
  }

.oval {
    position: absolute;
    top: 43%;
    left: 38%;
    transform: scale(0.8) translate(-6%, -50%);
    z-index: 100;
    // animation: pulse-black 2s infinite, apearance 1s forwards;
    cursor: pointer;
    // opacity: 0;
    // animation-delay: 5s, 4s;
    // transition: all 0.2s;
    transform-origin: 0 0;

    @media only screen and (max-width: ${SCREEN.MOBILE}) {
      transform: scale(0.8) translate(-6%, -48%);
    }
}

.watch-the-video {
   position: absolute;
    top: 43.5%;
    left: 36%;
    transform: scale(0.8) translate(-5%, -14.5%);
    z-index: 100;
    font-size: 12px;
    font-weight: 800;

    @media only screen and (max-width: ${SCREEN.MOBILE}) {
    top: 42.7%;
    left: 39.5%;
    }
}

  @keyframes apearance {
    0% {
      opacity 0;
    }


    100% {
      opacity: 1;
    }
  }

  @keyframes pulse-black {
    0% {
      transform: scale(0.7) translate(-50%, -50%);
      // box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    50% {
      transform: scale(0.8) translate(-50%, -50%);
      // box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.7) translate(-50%, -50%);
      // box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }

  .hidden {
    transform: translateX(-100000px);
  }

  .apply-menu {
    z-index: 1;
  }

  .open-menu-on-hover {
    top: 0;
    margin: 0;
    height: auto;

    > button {
      margin-bottom: 0 !important;
    }
  }

  .pl-12px {
    padding-left: 12px;
  }

  .animation-holder {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    margin-bottom: 1rem;
    line-height: 1.5;

    > * {
      position: relative;
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      max-width: 100%;
    }

    

  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {

    h3 {
      font-size: 24px !important;
      }

    .mt-60px {
      margin-top: 0 !important;
    }

    padding: 100px;
    padding-top: 0 !important;
    padding-bottom: 50px !important;

    .animation-holder {
      max-width: unset;
      min-height: 300px;
      // transform: translateY(-100px);
    }

    .pl-12px {
      padding-left: 5px;
    }
  }
`

const Oval = styled.img``

const ChromeExtension = styled.img`
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    width: 50px;
  }
`

const AnimationContainer = styled(FixedContainer)`
  
  margin-left: 0px;

   @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    padding: 10px;
  }
`

const HeadingsContainer = styled.div`
  position: relative;
  z-index: 1;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 640px;
  height: 712px;
  padding-left: 24px;
  margin-right: 20px;
   animation: ${load} 3s ease both;
  width: 100%;
  background: white;

  > h1 {
    font-size: 6.5rem;
    cursor: pointer;
    transition: all 0.3s;
    user-select: none;
  }

  &:before {
    // margin-top: 75px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 600px;
    width: 600px;
    content: '';
    background-color: var(--secondary);
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 8px;
    max-width: unset;
    height: 400px;
    align-items: center;
    // transform: translateY(100px);
    &:before {
      width: 100%;
    }

    h1 {
    line-height: 1.2;
      font-size: 70px;
    }

    img {
      // max-width: 640px !important;
      width: 640px !important;
      height: 712px !important;
      // height: 90% !important;
      // transition: all 3s ease;
    }

    &:before {
      
      @media only screen and (max-width: ${SCREEN.MOBILE}) {
        height: 420px;
        width: 500px;
        left: -100px;
        animation: none;
        transition: none;
      }
    }
  }
`


const Rectangle = styled.div`
  background-color: rgba(236, 251, 112, 1);
  width: 290px;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;

  > h4 {
    margin: 4px !important;
    }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    width: 200px;
    > h4 {
      font-size: 22px !important;
      width: 400px;
    }   
  }
`

const DescriptionContainer = styled.div`
  flex: 1 1 50%;
  max-width: 562px;
  margin-left: 22px;

  > h5 {
    font-size: 25px;
    font-weight: 600;
    line-height: 1.7;
    width: 500px;
    margin-left: 100px;
    margin-bottom: 100px;

    @media only screen and (max-width: ${SCREEN.MOBILE}) {
    max-width: 350px !important;
    margin-left: 70px;
    }
  }

  .extension-container {

    display: flex;
    flex-direction: row;
    align-items: center;
    transform: translate(210px, -35px);

    .chrome-extension {
      transform: translateY(10px);
    }

    @media only screen and (max-width: ${SCREEN.MOBILE}) {
          // margin-top: -100px;
          transform: translate(10px, -10px);
    }

  }

  .description {
    font-weight: 700;
    line-height: 40px;
    transform: translateX(-20px);
    width: 620px;
    height: 343px;
    font-size: 28px;
    margin-bottom: 20px !important;

    @media only screen and (max-width: ${SCREEN.MOBILE}) {
      width: 100%;
      transform: translateX(-60px);
      font-size: 22px;
      margin-bottom: -20px !important;
      line-height: 1.4;

    }
  }

  .description-2 {
    margin-top: -30px;
    width: 540px;
    font-weight: 600;
    color: #A1AEB7;
    font-family: 'Ubuntu';
    transform: translateX(120px);
    font-size: 22px;
    line-height: 1.3 !important;

    >div {
      width: max-content;

      @media only screen and (max-width: ${SCREEN.MOBILE}) {
        width: 100%;
      }
    }

    @media only screen and (max-width: ${SCREEN.MOBILE}) {
      width: 96%;
      font-size: 16px;
      transform: translate(6px, 50px);
      margin-top: 0px;
    }
  }

  .extension-icon {
        cursor: pointer; 
        transform: translate(7px, 4px)
  }

  .download-extension {
    font-weight: 400;
  }

  .info{
  display: flex;
  flex-direction: column;
  max-width: max-content;
  row-gap: 10px;
  margin-top: 40px;
  transform: translateX(-25px);
  margin-left: 100px;

  h6 {
    font-size: 16px;
    font-weight: 400;
    color: rgba(161, 174, 183, 1);
    font-family: 'Ubuntu';
    margin: 0;
  }

  h4 {
    font-size: 34px;
    font-weight: 400;
    margin: 0;
    width: 800px;
  }

   @media only screen and (max-width: ${SCREEN.MOBILE}) {
    // margin-left: 25px;
    > h5 {
    max-width: 400px !important;
    font-size: 16px !important;
    font-weight: 600;
    line-height: 1.7;
    margin: 0;
  }

  > h4 {
    font-size: 22px;
    width: 400px;
    }

  .info{
    max-width: 350px;
  }

  }
}

  div.d-flex button {
    white-space: nowrap;
    margin-bottom: 10px;
    margin-right: 12px;

    > svg {
      margin-left: 14px;
    }
  }

  small {
    max-width: 400px;
  }

  dialog {
    padding: 0;
    z-index: 1100;
    border-radius: var(--border-radius);
    background: transparent;
    border: 0;
    overflow: hidden;
    box-shadow: 0 0 0 100vmax rgb(0 0 0 / 0.65), 0 0 0.3rem rgb(0 0 0 / 0.44);
    position: fixed;
    inset: 0;
    margin: auto;
    width: min(90vw, 1050px);
    aspect-ratio: 16 / 9;

    iframe {
      vertical-align: middle;
      width: 100%;
      height: 100%;
    }
  }

  //TODO: Need TO BE REMOVED WITH APPLY DIALOG
  dialog.apply-dialog {
    background: #fff !important;
    padding: 0;
    z-index: 1100;
    border-radius: var(--border-radius);
    background: transparent;
    border: 0;
    overflow: hidden;
    box-shadow: 0 0 0 100vmax rgb(0 0 0 / 0.65), 0 0 0.3rem rgb(0 0 0 / 0.44);
    position: fixed;
    inset: 0;
    margin: auto;
    width: min(90vw, 800px);
    aspect-ratio: unset;

    .apply-dialog-header {
      background-color: var(--secondary);
      padding: 20px 30px;

      h3 {
        font-weight: normal !important;
        font-size: 34px;
      }
    }

    ul {
      list-style-type: none;
      padding-right: 30px;
      padding-left: 30px;

      li {
        border-top: 1px solid #000;
        padding: 20px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:first-child {
          border-top: none;
        }

        &:hover {
          background-color: var(--secondary);
        }

        a {
          font-weight: 600;
          font-size: 18px;
          color: #000;
          text-decoration: none;
        }
      }
    }

    .close-icon {
      background: transparent;
      top: 10px;
      right: 10px;
      text-align: center;

      svg {
        width: 27px !important;
      }
    }
  }

  .close-icon {
    position: absolute;
    background: #fff;
    border-radius: 100%;
    padding: 0;
    width: 40px;
    height: 40px;
    right: 2px;
    top: 2px;
    cursor: pointer;
    opacity: 1;
    transition: 0.3s ease-in-out opacity;

    &:hover {
      opacity: 0.75;
    }
  }

  
  @media only screen and (max-width: ${SCREEN.MOBILE}) {

    allign-items: center;
    justify-content: center;

    dialog.apply-dialog {
      aspect-ratio: unset;

      .apply-dialog-header {
        padding: 20px;
      }

      h3 {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 24px;
      }

      ul li {
        padding: 10px 10px;
      }
    }

    > h5 {
      margin-top: 0;
      font-size: 20px;
      width: 400px;
    }

    > p:first-child {
      margin-top: 0;
    }

    small {
      max-width: 290px;
    }
  }
`
const FadeOutText = styled(H1)`
margin-bottom: 150px;
margin-left: 60px;
  animation: ${fadeOut} 3s ease both;
  position: absolute;

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
  margin-top: 50px;  
  margin-bottom: 50px !important; 
  }
`

const FadeInText1 = styled(H1)`
  font-size: 120px !important;
  border: 2.5px !important;
  letter-spacing: 0;
  animation: ${fadeInText1} 1s ease both;
  animation-delay: 2.5s;

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    font-size: 65px !important;
  }
`

const FadeInText2 = styled(H1)`
  font-size: 120px !important;
  letter-spacing: 0;
  animation: ${fadeInText2} 1.5s ease both;
  animation-delay: 2.5s;
   @media only screen and (max-width: ${SCREEN.MOBILE}) {
    font-size: 65px !important;
  }
`

const FadeInText3 = styled(H1)`
    font-size: 120px !important;
  letter-spacing: 0;
  animation: ${fadeInText3} 1.5s ease both;
  animation-delay: 2.5s;

  &:hover {
    color: var(--secondary);
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    > span {
      letter-spacing: -3px;
    }
    font-size: 65px !important;
  }
`

const FadeInText4 = styled.div`
  display: flex;
  padding: 8px;
  line-height: 1.2;
  animation: ${fadeInText4} 1s ease both;
  animation-delay: 2.5s;
  transition: 0.3s;
    margin-bottom: 130px;


  > span {
    margin: 0;
    font-size: 40px;
    font-weight: 400 !important;
    width: 600px;
    margin-top: 20px;

    @media only screen and (max-width: ${SCREEN.MOBILE}) {
      font-size: 15px !important;
      transform: translateY(-15px);
    }
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    width: 300px; 
    font-size: 15px;
    margin-top: 0px;
    // padding: 8px;
    height: 36px;
    margin-left: 0;
    margin-bottom: 0;
    > span {
      font-size: 20px;
      line-height: 28px;

    }
  }
`

const TextStyle = styled.span`
  background-color: #ECFB70;

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    .new-line {
      display: none;
    }
  }
`

const HeroSection = ({ setMobileCTAVisible, handleModal, programs, showModal = false }) => {
  const callback = useCallback(
    entries => {
      entries.forEach(({ isIntersecting }) => {
        setMobileCTAVisible(!isIntersecting)
      })
    },
    [setMobileCTAVisible],
  )

  useEffect(() => {
    let options = {}

    let observer = new IntersectionObserver(callback, options)
    observer.observe(ref.current)
    return () => {
      observer.disconnect()
      setMobileCTAVisible(true)
    }
  }, [])

  //const [programs, setPrograms] = useState([])
  const [ah, setAh] = useState(true)

  /*useEffect(async () => {
    fetch(`https://backend.buildup.am/programs`)
      .then(response => response.json())
      .then(response => {
        setPrograms(response.filter(program => program.enabled))
      })
  }, [])*/

  let interval

  const updateTest = () => {
    interval = setInterval(() => {
      setAh(prev => !prev)
    }, 7000)
  }

  const closeModal = (name = "modal") => {
    if(name === 'programs-modal'){
      window.history.replaceState({},"Buildup",'/')
    }
    let modal = document.getElementById(name)
    modal.classList.add('d-none')
    const iframe = document.getElementById('youtube')
    const currentSource = iframe.src
    iframe.src = ''
    iframe.src = currentSource
  }

  const openModal = () => {
    let modal = document.getElementById('modal')
    modal.classList.remove('d-none')
  }

  useEffect(() => {
    updateTest()

    return () => clearInterval(interval)
  }, [])

  const ref = useRef()

  return (
    <MainContainer>
    <Container ref={ref} className='mb-100px'>
      <AnimationContainer>
        <div className='animation-holder'>
          <HeadingsContainer className={ah ? '' : 'hidden'}>
            <FadeOutText large>
              {getTranslation('buildup', 'BUILDUP')}
            </FadeOutText>
            <FadeInText1 inverted className='mt-60px'>
              <span className='pl-12px'>
                {
                  getTranslation('A', 'A', true)
                }
              </span>
            </FadeInText1>
            <FadeInText1 inverted>{
                getTranslation('PROBLEM', 'PROBLEM-', true)
              }</FadeInText1>
            <FadeInText2 inverted>
              {getTranslation('based', 'BASED')}
            </FadeInText2>
            <FadeInText3>{getTranslation('SCHOOL', 'SCHOOL')}</FadeInText3>
            <FadeInText4>
              {getTranslation('for_the_anyone', 'for the critical thinking and problems solving in tech', true)}
            </FadeInText4>
          </HeadingsContainer>

          <img src={otterGIF} alt='' className={ah ? 'hidden' : ''} />
          <Oval src={oval} className='oval'></Oval>
          <span className='watch-the-video'>{
              getTranslation("watch-the-video", "", true)
            }</span>
          <PlayIcon onClick={openModal} className='playIcon' />
        </div>

        <DescriptionContainer>
          {showModal &&
          <dialog open className='apply-dialog' id="programs-modal">
            <div className='apply-dialog-header'>
              <h3>
                {
                  getTranslation("upcoming-programs", "", true)
                }
              </h3>
            </div>
            <div className='d-flex'>
              <div className='close-icon ms-auto'>
                <CloseIcon onClick={() => closeModal("programs-modal")} />
              </div>
            </div>
            <ul>
              {programs.map(program => {
                return (
                  <li key={program.key}>
                    <a href={`/program/${program.key}`}>{program.data.title}</a>
                    <ArrowIcon />
                  </li>
                )
              })}
            </ul>
          </dialog>
          }
          <dialog open className='d-none' id='modal'>
            <div className='d-flex'>
              <div className='close-icon ms-auto'>
                <CloseIcon onClick={() => closeModal()} />
              </div>
            </div>

            <iframe
              width='560'
              height='315'
              src='https://www.youtube.com/embed/GAODVkZsEyI'
              id='youtube'
              title='YouTube video player'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowfullscreen
            ></iframe>
          </dialog>
          <div className='extension-container'>
          <h6 className='download-extension'>
            {
              getTranslation("chrome-extension", "Download <b>BU Chrome Extension</b>", true)
            }
          </h6>
          <a className='extension-icon' href='/extension'>
            <ChromeExtension src={extension} className='chrome-extension'></ChromeExtension>
          </a>
          </div>
          <h5 className='description'>
            {
              getTranslation('program-description', "Empowering the Next Generation with AI-Driven Education.<br>BuildUp School is expanding worldwide to help learners build digital products and develop critical skills. Education will always remain free for Armenian citizens as we scale internationally.", true)
            }
          </h5>
          <div className='description-2'>
          <span>
            {
              getTranslation('program-description-2', "Master both professional skills and English fluency through practical, challenge-based learning.<br>", true)
            }
          </span>
           <TextStyle highlight>{
              getTranslation("highlight-description", "", true)
            }<br className='new-line'></br></TextStyle>
           <span>
            {
              getTranslation('program-description-3', "forever, ensuring equal opportunity to build a global career.", true)
            }
          </span>
          </div>
        </DescriptionContainer>

      </AnimationContainer>
      
    </Container>
    <AlbertSection text='Is BuildUp right for you? Chat with our founder Albert'/>
    <AlbertSectionResponsive text='Is BuildUp right for you? Chat with our founder Albert'/>
   
    <NewFocusSection />
    </MainContainer>
  )
}

export default HeroSection
