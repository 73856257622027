import * as React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { Button } from 'components/styled/button'

import { SCREEN } from 'styles/screens'


const ImageContainer = styled.div`
  flex: 1 1 50%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding-right: 100px;
  padding-bottom: 140px;
`

const FixedContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 55px;
   max-width: 1400px;
   border: 2px solid rgba(236, 251, 112, 1);
  border-radius: 5px;
  width: 1500px;
  height: 150px;
  transform: translateY(80px);
  
  > h5{
    font-weight: 700;
    width: 400px;
  }

  .albert-img{
    width: 400px;
    transform: translateY(-66px);
  }

  // @media only screen and (max-width: ${SCREEN.MOBILE}) {
  //   flex-direction: row;
  //   // align-items: flex-start;
  //   width: 700px;
  // }
   @media only screen and (max-width: ${SCREEN.MOBILE}) {
  //   padding-inline: 10px;
  //   flex-direction: column;
    
  //   > h5 {
  //     font-size: 18px;
  //   }

  //   .albert-img {
  //     width: 50%;
  //   }
  // }
  display: none;
`

const CallButton = styled(Button)`
  font-size: 32px;
  padding: 16px !important;
  font-weight: 500 !important;
  white-space: nowrap;
  target: "https://calendly.com/buildup-ab/30min?back=1&month=2024-10";

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    font-size: 18px;
    padding: 10px !important;
  }
`

const AlbertSection = ({text}) => (
    <FixedContainer style={{margin: 50}}>

    <h5>{text}</h5>

    <div className='albert-img'>
      <StaticImage
          src="../../images/albert 1 (2).png"
      />
    </div>
    <a href='https://calendly.com/buildup-ab/30min?back=1&month=2024-10'>
    <CallButton>Book a call</CallButton>
    </a>
  </FixedContainer>
  )

export default AlbertSection