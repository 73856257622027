import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import styled from 'styled-components'
import { TitleContainer, TitleText} from 'components/styled/typography'
import AlbertSection from 'components/styled/AlbertSection'
import { SCREEN } from 'styles/screens'
import AlbertSectionResponsive from 'components/styled/AlbertSection-Responsive'
import { getTranslation } from 'data/dictionary'

const Main = styled.div`

    .wall-of-love{
    width: 100%;
    }

@media only screen and (max-width: ${SCREEN.MOBILE}) {
      h3 {
        font-size: 24px !important;
      }
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
`
const Container = styled.div`
    margin-top: 200px;
    transform: translateY(57px);
    margin-bottom: 200px;

    @media only screen and (max-width: ${SCREEN.MOBILE}) {
        margin-bottom: -160px;
    }
`

const Feedback = styled.iframe`
     width: 100%;
  height: 3000px;
  border: none;
  overflow: auto !important;
`

const FeedbackWrapper = styled.div`
    padding: 30px 30px 0;
    background-color: rgb(249, 249, 249);
    display: flex;
    flex-wrap: wrap;
    height: 600px;
    width: 100%;
    max-height: 800px;
    overflow-y: auto;
    margin-top: 100px;
`

const Text = 'Yes, let’s double my salary'

const WallOfLoveSection = () => {
    return (
              
        <Main>
            <div className='wall-of-love'>
             <TitleContainer>
            <TitleText>{
                    getTranslation("the-wall-of-love", "", true)
                }</TitleText>
            </TitleContainer>
            <FeedbackWrapper>
            <Feedback src='https://shoutout.io/embed/Buildup/' />
            </FeedbackWrapper>
            <Container>
            </Container>
            <AlbertSection text='Yes, let’s double my salary'/>
            </div>
            <AlbertSectionResponsive text='Yes, let’s double my salary'/>
    
        </Main>

    )
}

export default WallOfLoveSection;

