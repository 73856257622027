import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import styled from 'styled-components'
import { TitleContainer, TitleText} from 'components/styled/typography'
import { SCREEN } from 'styles/screens'
import { getTranslation } from 'data/dictionary'

const Main = styled.div`
    .book{
        background-image: url(book-opened-svgrepo-com 1.png);
    }

    margin-top: 300px;

      @media only screen and (max-width: ${SCREEN.MOBILE}) {
      h3 {
        font-size: 24px !important;
      }

      .line{
        display: none;
      }
        margin-top: 100px;
}
`

const ImageContainer1 = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: centre;
    row-gap: 20px;
    margin-top: 100px;
    margin-bottom: 200px;

    @media only screen and (max-width: ${SCREEN.MOBILE}) {
        margin-top: 0px;
        margin-bottom: 0px !important;
        row-gap: 0px;
    }
`
const ImageContainer2 = styled.div`
     display: flex;
    // align-items: center;
     transform: translateY(375px);
        justify-content: center;
        position: relative;
        margin-top: 100px;

`
const LittleText = styled.p`
    font-family: 'lexend';
    font-weight: 400 !important;
    width: 300px;
    line-height: 1;   
    font-size: 14px !important;
    text-align: center;

    @media only screen and (max-width: ${SCREEN.MOBILE}) {
        transform: translateY(-22px);
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    // padding-left: 100px;
    // padding-right: 100px;

    @media only screen and (max-width: ${SCREEN.MOBILE}) {
        flex-direction: column;
        align-items: center;
        
        > div {
            margin-bottom: 0px;}
    }
`

const H6 = styled.h6`
    font-weight: 500;
    margin-top: 10px;
`



const ProcessSection = () => {
    return (
        <Main>
            <TitleContainer>
            <TitleText>
                {
                    getTranslation("our-process", "What is our process?", true)
                }
            </TitleText>
            </TitleContainer>
            <ImageContainer2>
                <StaticImage src="../../images/Line 1.png" className='line'/>
                </ImageContainer2>
            <Container>
                <ImageContainer1>
                    <StaticImage src="../../images/Ellipse 1.png" />
                    <StaticImage src="../../images/Group 61.png" />
                    <H6>Prepare</H6>
                    <LittleText>
                        {
                            getTranslation("prepare-text", "Lorem ipsum dolor sit amet, consectetur adipisicing, sed do eiusmod temp", true)
                        }
                    </LittleText>
                </ImageContainer1>
                <ImageContainer1>
                {/* <StaticImage src="../../images/book-opened-svgrepo-com 1.png" className='book'/> */}

                    {/* <StaticImage src="../../images/Ellipse.png"  className='book'/> */}
                    <StaticImage src="../../images/book (2).png" width={228} height={228}/>

                    <StaticImage src="../../images/Group 62.png" />
                    <H6>Learn</H6>
                    <LittleText>
                    {
                            getTranslation("learn-text", "Lorem ipsum dolor sit amet, consectetur adipisicing, sed do eiusmod temp", true)
                        }
                    </LittleText>
                </ImageContainer1>
                <ImageContainer1>
                    <StaticImage src="../../images/key.png" width={228} height={228}/>
                    <StaticImage src="../../images/Group 63.png" />
                    <H6>Build</H6>
                    <LittleText>
                    {
                            getTranslation("build-text", "Lorem ipsum dolor sit amet, consectetur adipisicing, sed do eiusmod temp", true)
                        }
                    </LittleText>
                </ImageContainer1>
                <ImageContainer1>
                    <StaticImage src="../../images/person-elipse.png" width={228} height={228}/>
                    <StaticImage src="../../images/Group 64.png" />
                    <H6>Get hired</H6>
                    <LittleText>
                    {
                            getTranslation("get-hired-text", "Lorem ipsum dolor sit amet, consectetur adipisicing, sed do eiusmod temp", true)
                        }
                    </LittleText>
                </ImageContainer1>
                
            </Container>
           
        </Main>

    )
}

export default ProcessSection;